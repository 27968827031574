.header_container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0 16px;
}
.main_banner{
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: -1;
   object-fit: cover;
}
.caption_div{
    padding: 20px 32px 20px 32px;
    border-radius: 1rem;
    z-index: 10;
    opacity: 0.8;
}

.title{
    font-size: 72px !important;
    text-transform: capitalize;
    color: #F9FAFB;
    font-weight: 400;
    margin-bottom: 30px;
    letter-spacing: -1px;
}
.caption{
    font-size: 1.875rem !important;
    text-transform: capitalize;
    color: white;
    font-weight: 300;
}
.layer{
    background-color: black;
}

@media screen and (max-width:450px) {
    .title{
        font-size: 3.125rem !important;
    }
    .caption{
        font-size: 1.5625rem !important;
    }  
}
@media screen and (max-width:366px) {
    .title{
        font-size: 1.875rem !important;
    }
}