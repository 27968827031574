.store_card{
    margin: 1.25rem 0;
}

.store_logo{
    width: 4.6875rem;
    height: 4.6875rem;
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.582);
}
.store_logo img{
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}
.card_body{
    min-width:23.75rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.242);
    border-radius: 30px !important;
    min-height: 100%;
}
.card_img_div{
    width: 400px;
    aspect-ratio: 16/9;
    border-radius: 30px !important;
}
.card_img_div img{
    object-fit: cover;
}
.card_img_div{
    border-radius: 0.9375rem;
    min-height: 10.9375rem;
    position: relative;
    overflow: hidden;
    transition: all .5s;
}

.card_img_div_layer{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right:0;
    opacity: 0;
    transition: all .5s;
}
.back_img{
    width: 100%;
}
.card_img_div:hover{
    transform: rotateY(180deg);
}
.card_img_div:hover .card_img_div_layer{
    transform: rotateY(180deg);
    opacity: 1;
}

.card_price{
    font-weight: 400;
}
.card_price_en{
    font-weight: 400;
    font-family: 'Times New Roman', Times, serif;
}
.arrow_icon{
    background-color: var(--dark_white);
    color: white;
    padding: 3px 4px;
    border-radius: 50%;
    border: 1px solid var(--dark_white);
    cursor: pointer;
}
.arrow_icon:hover{
    box-shadow:  0 0 3px 3px rgba(128, 128, 128, 0.233);
}

@media screen and (max-width:450px) {
    .card_body{
        min-width:100%;    
    }
    .card_img_div{
        width: 100%;
    }
    .store_card{
       width: 100%;
    }
}
@media screen and (max-width:390px) {
    .card_body{
        min-width:98%;
    }
    .card_img_div{
        width: 100%;
        height: 14.0625rem;
    }
    .card_img_div img{
        object-fit: cover;
    }
}