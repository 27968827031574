.store_item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.208);
    border-radius: 0.625rem;
    padding: 3.125rem 4.375rem;
    max-width: 300px;
    overflow: hidden;
    transition: all .4s;
}
.store_item:hover{
    transform: translateY(-10px);
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.622)
}
.store_card{
    cursor: pointer;
}
.store_logo{
    border-radius: 50%;
    width: 9.375rem;
    height: 9.375rem;
    margin-bottom: 0.625rem;
    position: relative;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.333);
}
.store_logo img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit:cover
}

.store_desc {
    max-width:270px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.3s ease;
    cursor: pointer;
    color: var(--dark_white);
  }

.filter_box{
    border: 1px solid transparent;
    border-radius: 0.1875rem;
    padding: 0.3125rem 0.5rem;
    cursor: pointer;
    background-color: var(--main_color);
    color: var(--white);
    transition: all .4s;
}
.filter_box:hover svg{
    cursor: pointer;
    transition: all .3s;
}
.filter_box:hover{
   background-color: white;
   color: var(--main_color);
   border: 1px solid var(--main_color);
}
.filter{
    font-size: 1.125rem;
}

.category_label{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.category_label img{
    margin-inline: 10px;
    width: 40px;
}
.select_category_field{
    width: 400px;
}


_________________________________________store profile____________________________________________________
.main_container{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content{
    padding: 20px 0;
}
.myStore_header{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.profile_body{
 background-color: #F9F9FB;   
 margin-top: 10px;
 width: 100%;
 padding: 20px 0;
}
.customGiftCard{
    width: 480px;
    aspect-ratio: 16/9;
    border-radius: 30px;
}
.customGiftCard img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}
.link_icon{
    position: absolute;
    right: -25px;
    top: 30px;
    background-color: var(--sub_color);
    padding: 10px;
    border-radius: 50%;
    color: white;
    transform: translate(-50%,-50%);
}

@media screen and (max-width:550px) {
    .customGiftCard{
        width: 420px;
    }
    .select_category_field{
        width: 98%;
    }
}
@media screen and (max-width:500px) {
    .customGiftCard{
        width: 400px;
    }
    .select_category_field{
        width: 98%;
    }
}
@media screen and (max-width:450px) {
    .customGiftCard{
        width: 300px;
    }
    .select_category_field{
        width: 98%;
    }
}
@media screen and (max-width:350px) {
    .store_item{
      padding: 20px 30px;
      max-width: 200px;
    }
    .customGiftCard{
        width:250px;
    }
}


