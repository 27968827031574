.general_info_form {
  min-height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.625rem;
}
.form {
  padding: 20px;
  min-width: 40%;
  border-radius: 0.625rem;
  box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.364);
}
.field {
  display: flex;
  flex-direction: column;
  margin: 3.125rem 0;
  width: 100%;
  position: relative;
}
.field label {
  font-weight: 700;
  margin-bottom: 0.3125rem;
  text-align: start;
}
.save_btn {
  background-color: var(--main_color);
  color: white;
  padding: 0.625rem 0.9375rem;
  border-radius: 0.9375rem;
  font-weight: 700;
  min-width: 6.875rem;
  transition: all 0.3s;
  min-width: 9.375rem;
}
.save_btn:hover {
  background-color: rgb(9, 75, 161);
  transform: translateX(0.3125rem);
}
.form_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.875rem;
  text-align: center;
}
.form_header h3 {
  margin: 0;
  font-size: 1.5625rem;
  font-weight: 600;
  margin-bottom: 0.3125rem;
}
.lock_img {
  width: 6.25rem;
}
.lock_img img {
  width: 100%;
}
.options {
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

/* _____________________________________________________verify________________________________________ */
.modal_container {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
}
.modal_body {
  background-color: white !important;
  border-radius: 10px;
}
.verify_form {
  margin: 30px 0;
  position: relative;
}
.verify_form input {
  width: 100%;
  border: none;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.152);
  border-bottom: 2px solid rgba(0, 0, 0, 0.453);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.37);
  font-size: 40px;
}
.select_type {
  margin-inline: 0.625rem;
  background-color: var(--vanilla);
  font-size: 1.25rem;
  padding: 0.4375rem 0.9375rem;
  border-radius: 0.625rem;
  color: var(--main_color);
  cursor: pointer;
  transition: all 0.4s !important;
}
.select_type:hover {
  scale: 1.1;
}

/* _____________________________________________________reset Password________________________________________ */

.reset_password input {
  font-size: 10px;
}
