.container_body{
    min-height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0.625rem 0;
}
.title{
    font-size: 1.125rem;
    color: var(--dark_white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.name{
    font-size: 40px;
    text-align: center;
}
.profile_img{
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    margin: auto;
}
.profile_img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.list_item{
    padding: 1.875rem;
    background-color: var(--sub_color);
    box-shadow: 0 0 0.1875rem black;
    margin: 1.875rem 0;
    min-width: 12.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    cursor: pointer;
    transition: all .4s;
}

.list_item span{
    font-size: 1.3125rem;
    color:white
}
.list_item_icon{
    color: var(--sub_color);
    font-size: 2.1875rem;
    margin-bottom: 0.625rem;
    transition: all .4s;
    color:var(--main_color)

}

.list_item:hover .list_item_icon{
    color: white;
}


/* acc settingg */

.acc_setting_body{
    padding: 1.25rem;
    overflow-y: auto;
    scrollbar-color: transparent transparent ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form_container{
    padding: 1.25rem;
    border-radius: 0.625rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.284);
    margin-bottom: 1.25rem;
    width: 80%;
}
.verify_container{
    box-shadow: 0 0 0.1875rem yellow;
}

/* all discounts  */
.discount_body{
    min-height: 90vh;
    padding: 2.5rem;
}
.disc_content{
    padding: 1.875rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.263);
    margin-bottom: 2.5rem;
    border-radius: 0.625rem;
}
.red_shadow{
    box-shadow: 0 0 0.1875rem var(--main_color);
}
.disc_content ul{
    padding: 0;
}
.disc_content ul li{
    padding: 0.3125rem;
}
.list_icon{
    color: var(--slate_blue);
}
@media screen and (max-width:510px) {
    .form_container{
        width: 98%;
    }
}

@media screen and (max-width:430px) {
    .list_item{
        min-width: 170px;
    }
}
@media screen and (max-width:390px) {
    .main_row{
        flex-direction: column !important;
        align-items: center;
    }
}