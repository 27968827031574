.main_title_div{
    display: flex;
    align-items: center;
}
.logo{
    width: 90px;
}
.main_title_div h2{
    margin: 0;
    margin-inline: 20px;
}

@media screen and (max-width:400px) {
    .logo{width: 50px;}
}

@media screen and (max-width:300px) {
    .logo{display: none;} 
}