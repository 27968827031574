.footer{
    padding: 1.25rem;
    background-color: var(--deep_main_color);
    width: 100vw;
}

.footer_links{
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer_links a svg{
    font-size: 2.1875rem;
    margin-inline: 1.25rem;
    color: white;
    transition: all .4s;
}
.footer_links a svg:hover{
    transform: translateY(-0.3125rem);
}

@media screen and (max-width:480px) {
    .footer_links{
        margin-top: 30px;
    }
}

@media screen and (max-width:300px) {
    .footer_links a svg{
        margin-inline: 10px;
    }
}