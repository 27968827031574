.support_img{
    width: 300px;
}
.help_body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 88vh;
}
.message{
    font-size: 17px;
}
@media screen and (max-width:430px) {
 .help_body{
    padding-inline: 10px;
 }
}
@media screen and (max-width:350px) {
    .support_img{
        width: 100%;
    }
    .help_body div{
        text-align: start !important;
    }
}