.main_body{
    width: 70%;
    margin: 3.125rem auto;
}
.field{
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;
    width: 100%;
    position: relative;
}
.field label{
    font-weight: 400;
    color: var(--dark_white);
    margin-bottom: 0.3125rem;
    font-size: 20px;
}
.field input{
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.196);  
}
.phone_num{
    display: flex;
    align-items: center;
    background-color: white;
    height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.425);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.ar_phoneNum{
    flex-direction: row-reverse;
}
.select_input{
    border: none !important;
    width:20%;
    font-family: Arial, Helvetica, sans-serif;
}
.phone_input{
    border-radius: 0 5px 5px  0;
    width: 80%;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    height: 100%;
    border: none !important;
}
.select_input * {
    border: none !important;
}
.desc_field{
    height: 100px;
    padding: 2px;
    border-radius: 5px;
}

.save_btn{
    background-color: var(--sub_color);
    color: white;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.5rem;
    font-weight: 700;
    min-width: 8.75rem;
    transition:all .3s;
}
.save_btn:hover{
    background-color: var(--sub_color_light);
    transform: translateX(0.3125rem);
}
@media screen and (max-width:680px) {
    .main_body{
        width: 95%;
    }

}
@media screen and (max-width:500px) {
    .select_input{
        width:40%;
    }
    .phone_input{
        width: 60%;
    }

}
