.side_bar{
    padding: 20px 0;
    padding-bottom: 0;
    border-radius: 40px 0 0 40px;
}
.header{
    border-bottom: 1px solid #2b2b2b2f;
}
.header h2{
    color: var(--main_color);
    font-weight: 600;
}
.close_icon{
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all .4s
}
.close_icon:hover{
    scale: 1.1;
    color: var(--main_color);
}
.list{
    padding: 0;
}
.list_item{
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.388);
    padding: 0.625rem;
    border-radius: 30px;
    margin-bottom: 0.875rem;
}
.pro_color_item{
    background-size: cover;
    background-position: 50% 50%;
}
.list_item h4{
    font-weight: 600;
    text-align: center;
    margin-bottom: 0.625rem;
}
.sub_list_item{
    margin:8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sub_list_icon,.sub_list_icon_ar{
    margin-right: 0.5rem;
    color: var(--main_color);
    background-color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.212);
    border-radius: 5px;
    padding: 8px;
    min-width: 20px;
}
.sub_list_icon_ar{
    margin-left: 0.5rem;
}
.controllers{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}
.trash_icon,.arrow_right_icon{
    margin-inline: 0.625rem;
    font-size: 0.9375rem;
    background-color: var(--main_color);
    color: white;
    padding: 0.5rem 0.5625rem;
    border-radius: 50%;
    cursor: pointer;
    border: 0.0625rem solid transparent;
    transition: all .4s;
}
.view_btn{
    background-color: white;
    color:black;
    padding:0.625rem 1.25rem;
    border-radius: 1.5625rem;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.54);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    min-width: 11.25rem;
    transition: all .4s;
}
.view_btn:hover{
    background-color: var(--sub_color);
    color: white;
}
.view_btn span,.eye{
    margin: 0;
    padding: 0;
}

.arrow_right_icon{
    background-color: var(--sub_color);
    color:white;
}
.arrow_right_icon:hover{
    background-color: white;
    color: var(--sub_color);
    border: 0.0625rem solid var(--sub_color);
}
.trash_icon:hover{
    background-color: white;
    color: var(--main_color);
    border: 0.0625rem solid var(--main_color);
}

.header{
    width: 100%;
    margin-bottom: 1.25rem;
 }
 .header_list{
    display: flex;
    justify-content:center;
 }
 .header_list_item{
    font-size: 1.125rem;
    font-weight:500;
    border-bottom: 0.25rem solid rgb(186, 186, 186);
    padding-inline: 3.125rem;
    color: rgb(186, 186, 186);
    cursor: pointer;

     }
 .active{
    color: var(--blue);
    border-bottom:4px solid var(--blue);
 }
 @media (max-width: 390px) {
    .header_list_item{
        font-size: 15px;
        padding-inline: 30px;
    
         }
   }
 @media (max-width: 290px) {
    .view_btn{
        min-width: 6.25rem;
        padding: 0.3125rem 0.5rem;
    }
   }

