.store_card{
    margin: 1.25rem 0;
}
.store_logo{
    width: 4.6875rem;
    height: 4.6875rem;
    border-radius: 50%;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.582);
}
.store_logo img{
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}
.card_body{
    min-width:23.75rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.242);
    border-radius: 30px !important;
    min-height: 100%;
}
.card_img_div{
    width: 400px;
    aspect-ratio: 16/9;
    border-radius: 30px !important;
}
.card_img_div img{
    object-fit: cover;
}
.filter_box{
    border: 1px solid transparent;
    border-radius: 0.1875rem;
    padding: 0.3125rem 0.5rem;
    cursor: pointer;
    background-color: var(--main_color);
    color: var(--white);
    font-weight: 600;
    transition: all .4s;
}
.filter_box:hover svg{
    cursor: pointer;
    transition: all .4s;
}
.filter_box:hover{
   background-color: white;
   color: var(--main_color);
   border: 1px solid var(--main_color);
}
.filter{
    font-size: 1.125rem;
}
.card_img_div{
    border-radius: 0.9375rem;
    min-height: 10.9375rem;
    position: relative;
    overflow: hidden;
    transition: all .5s;
}

.card_img_div_layer{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right:0;
    opacity: 0;
    transition: all .5s;
}
.back_img{
    width: 100%;
}
.card_img_div:hover{
    transform: rotateY(180deg);
}
.card_img_div:hover .card_img_div_layer{
    transform: rotateY(180deg);
    opacity: 1;
}

.controllers_icon{
    position: absolute;
    top:-0.5rem;
    right:-0.625rem;
    display: flex;
}
.controllers_icon_ar{
    position: absolute;
    top:-0.625rem;
    left:-0.9375rem;
    display: flex;
}

.card_price{
    font-family:'Times New Roman', Times, serif;
    color: black;
    font-weight: 500;
}
.controllers{
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.221);
    padding-bottom: 0.625rem;
    margin-inline: 0.625rem;
}
.arrow_icon{
    background-color: var(--slate_blue);
    padding: 8px 9px;
    color: white;
    border-radius: 50%;
    cursor: pointer;
}
.arrow_icon:hover{
    box-shadow:  0 0 3px 3px rgba(128, 128, 128, 0.233);
}
.configs_body{
    width: 70%;
    margin: auto;
}

@media screen and (max-width:680px) {
    .configs_body{
        width: 98%;
        margin: auto;
    }
}
@media screen and (max-width:480px) {
    .controllers{
        flex-direction: column-reverse;
    }
    .search_field{
        margin-bottom: 10px;
    }
}


@media screen and (max-width:450px) {
    .card_body{
        min-width:100%;    
    }
    .card_img_div{
        width: 100%;
    }
    .store_card{
       width: 100%;
    }
}
@media screen and (max-width:390px) {
    .card_body{
        min-width:98%;
    }
    .card_img_div{
        width: 100%;
        height: 14.0625rem;
    }
    .card_img_div img{
        object-fit: cover;
    }
}
