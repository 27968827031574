.container_body{
    min-height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0.625rem 0;
}
.title{
    font-size: 1.125rem;
    color: var(--dark_white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.integer{
    font-size: 6.25rem;
    font-family: 'Times New Roman', Times, serif !important;
}
.decimal{
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif !important;

}
.sar{
    font-size: 25px;
    font-weight: 400;
}
.name{
    font-size: 40px;
    text-align: center;
}
.profile_img{
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    margin: auto;
}
.profile_img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.list_item,.list_item_prevOP{
    padding: 1.875rem;
    background-color: var(--sub_color);
    box-shadow: 0 0 0.1875rem black;
    margin: 1.875rem 0;
    width: 15.625rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    cursor: pointer;
    transition: all .4s;
}

.list_item span,.list_item_prevOP span{
    font-size: 1.375rem;
    font-weight: 300;
    color:rgb(241, 240, 240);
    text-align: center;
}
.list_item_prevOP{
    background-color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.348);   
}
.list_item_prevOP span{
    color: black;
    font-weight: 400;
}
.list_item_icon{
    color: var(--sub_color);
    font-size: 2.1875rem;
    margin-bottom: 0.625rem;
    transition: all .4s;
    color:var(--main_color)
}

.list_item_prevOP:hover .list_item_icon{
    transform: rotate(360deg);
}
.list_item:hover .list_item_icon{
    color: white;

}


/* transfer */
.main_ul{
    margin:0;
    padding: 0;
    width: 50%;

}

.prev_process{
    padding: 0.625rem;
    background-color: rgba(239, 244, 247, 0.751);
    margin: 1.25rem 0;
    border-radius: 0.3125rem;
    padding: 0;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.338);
    overflow: hidden;
}
.sub_ul{
    margin: 0;
    padding: 0;
}
.sub_ul li{
    display: flex;
    justify-content: space-between;
    padding: 0.3125rem 0;
}
.sub_ul li:not(:last-child){
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.242);
}
.sub_ul li span{
    font-size: 1.25rem;
    margin-inline: 1.25rem;
    word-break: break-all;
}
.sub_ul_en li span{
    font-size: 1.125rem;
    font-family: 'Times New Roman', Times, serif;
}

.show_ul{
    display: block;
}
.hide_ul{
    display: none;
}
.counter_title{
    text-align: center;
    padding: 0.3125rem 0.625rem;
    background-color: var(--main_color);
    color: white;
    border-radius: 0.625rem;
    width: 9.375rem;
    margin: 0.625rem auto;
    margin-bottom: 0;
    font-family: 'Times New Roman', Times, serif;
}
@media screen and (max-width:768px) {
    .main_ul{
        width: 70%;
    }
}
@media screen and (max-width:450px) {
    .main_ul{
        width: 98%;
    }
}
@media screen and (max-width:430px) {
    .list_item{
        min-width: 170px;
    }

}
@media screen and (max-width:390px) {
    .main_row{
        flex-direction: column !important;
        align-items: center;
    }
}
@media screen and (max-width:300px) {
.list_item,.list_item_prevOP{
    width: 12.5rem;
}
}