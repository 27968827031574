.modal_container{
    background-color: rgba(0, 0, 0, 0.258);
    backdrop-filter: blur(0.1875rem);
}
.modal_body{
    background-color:var(--vanilla) !important;
    border-radius:0.625rem 0.625rem 0 0;
}
.modal_footer{
    background-color:var(--vanilla) !important;
    border-radius:0 0 0.625rem 0.625rem;
}
.close_btn,.logout_btn{
    border-radius: 1.5625rem !important;
    min-width: 6.25rem;
    font-size: 1.125rem;
    font-weight: 700;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5);
    padding: 0.625rem 0.9375rem !important;
    margin-right: auto !important;
}
.logout_btn{
    margin-right: 0 !important;
    margin-left: auto !important;
}

.close_btn_ar,.logout_btn_ar{
    border-radius: 1.5625rem !important;
    min-width: 6.25rem;
    font-size: 1.125rem;
    font-weight: 700;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5);
    padding: 0.625rem 0.9375rem !important;
    margin-left: auto !important;
}
.logout_btn_ar{
    margin-left: 0 !important;
    margin-right: auto !important;
}
.verify_code{
    font-size: 2.5rem;
    width: 90%;
    text-align: center;
}


.search_field{
    width: 95%;
    padding: 0.625rem;
}
.field{
    position: relative;
    margin: 40px 0;
}
.details_list{
    text-align:start;
    border-top: 0.0625rem solid rgba(0, 0, 0, 0.237);
    padding: 0;
    padding-top: 1.25rem;
}
.details_list li{
    font-size: 18px;
    margin: 0.625rem 0;
    display: flex;
    align-items: center;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
}
.details_list_en li{
    font-family: 'Times New Roman', Times, serif !important;
}
.coupon_form{
    margin-inline: 0.625rem;
    display: flex;
}
.coupon_form button{
    background-color: var(--sub_color);
    color: white;
    padding: 0.3125rem 0.625rem;
    border-radius: 0.625rem;
    margin-inline: 0.3125rem;
}
.list_icon{
    color: var(--slate_blue);
    margin-inline: 0.625rem;
}