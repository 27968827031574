.side_bar{
    padding: 1.25rem 0;
    padding-bottom: 0;
    color:  var(--font_color) !important;
    border-radius: 2.5rem 0 0 2.5rem;
}
.logo{
    width: 120px;
}
.header{
    border-bottom: 0.0625rem solid #2b2b2b2f;
}
.close_icon{
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all .4s
}
.close_icon:hover{
    scale: 1.1;
    color: var(--main_color);
}
.contact_list{
    text-align: start;
    padding-inline: 0;
}
.contact_list_item{
   border-bottom: #2525252f 0.0625rem solid;
   padding: 0.9375rem 0;
   cursor: pointer;
   display: flex;
   justify-content: space-between;
   transition: all .3s;
   font-size: 1.25rem;
}
.list_icons{
    font-size: 1.5625rem;
    margin-right: 0.9375rem;
    color:var(--main_color)
}

.contact_list a,.lang{
    color:  black;
    font-weight: 500;
    transition: all .4s;
}   
.contact_list_item:hover{
    transform: translateX(0.625rem);
}
.lang{
    font-family: "Cairo" !important;
}
.lang:hover{
    color: var(--main_color);
}
.contact_list a:hover .contact_list_item{
   color: var(--main_color);
}
.hidden_small,.wallet_icon_hidden{
    display: none;
}

@media screen and (max-width:538px) {
    .side_bar_signing_btns{
        display: flex !important;
    }
}
@media screen and (max-width:450px) {
   .hidden_small{
    display: block;
   }
}
@media screen and (max-width:355px) {
   .wallet_icon_hidden{
    display: block;
   }
}