.field{
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;
    width: 100%;
    position: relative;
}
.field label{
    margin-bottom: 0.3125rem;
    font-weight: 400;
    color: var(--dark_white);
}

.field input{
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.196);  
}
.save_btn{
    background-color: var(--main_color);
    color: white;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.5rem;
    font-weight: 700;
    min-width: 6.875rem;
    border: 1px solid transparent;
    transition:all .3s;
    min-width: 9.375rem
}
.save_btn:hover{
    background-color:transparent;
    color: var(--main_color);
    border: 1px solid var(--main_color);
    transform: translateX(0.3125rem);
}

.user_general_info .field{
    margin-bottom: 1.875rem;
}
.photo_field{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.photo_label{
    padding: 20px 40px;
    border: 1px solid var(--dark_white);
    border-radius: 10px;
    cursor: pointer;
    transition: all .4s;
}
.photo_label:hover{
    scale: 1.07;
}
.avatar_label{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    cursor: pointer;
    transition: all .4s;
}
.avatar_label:hover{
    scale: 1.07;

}
.avatar_label img{
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.img_icon{
    font-size: 30px;
    color: var(--slate_blue);
}

.phone_num{
    display: flex;
}
.ar_phoneNum{
    flex-direction: row-reverse;
}
.select_input{
    border: 1px solid rgba(0, 0, 0, 0.311);
    border-right: 1px solid rgba(0, 0, 0, 0.097);
    border-radius: 5px 0 0 5px;
    width:25%;
    font-family: Arial, Helvetica, sans-serif;
}
.select_input * {
    border: none !important;
}
.phone_input{
    border-left: none;
    border-radius: 0 5px 5px  0;
    width: 75%;
    font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width:600px) {
    .field h4{
        margin: 0;
        text-align: center;
    }
}

@media screen and (max-width:550px) {
    .select_input{
        width:35%;
    }
    
    .phone_input{
        width: 65%;
    }
}

@media screen and (max-width:430px) {
    .select_input{
        width:40%;
    }
    
    .phone_input{
        width: 60%;
    }
}
@media screen and (max-width:370px) {
    .select_input{
        width:50%;
    }
    
    .phone_input{
        width: 50%;
    }
}

@media screen and (max-width:250px) {
   .save_btn{
    min-width: 90%;
   }
}
