/* _________________________________________________________________Form_______________________________ */
.field{
    margin-bottom: 48px;
    text-align: start;
    font-weight: 600;
    position: relative;
}

.register_form{
    padding:0.9375rem 1.25rem;
    width: 100%;
    text-align: center;
    overflow: hidden;
}
.register_form label{
    text-align: start;
    margin-bottom: 0.1875rem;
}
.register_btn{
    width: 80%;
    background-color: var(--sub_color);
    color: white;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.9375rem;
    margin: 0.625rem 0;
    border: 1px solid transparent;
    font-weight: 600;
    transition: background-color .3s;
}
.register_btn:hover{
    background-color:var(--sub_color_light);
}
.confirm_password, .password_field{
    width: 50%;
}
.have_acc_p{
    letter-spacing: 1px;
}
.phone_num{
    display: flex;
}
.ar_phoneNum{
    flex-direction: row-reverse;
}
.select_input{
    border: 1px solid rgba(0, 0, 0, 0.311);
    border-right: 1px solid rgba(0, 0, 0, 0.097);
    border-radius: 5px 0 0 5px;
    width:30%;
    font-family: Arial, Helvetica, sans-serif;
}
.select_input * {
    border: none !important;
}
.phone_input{
    border-left: none;
    border-radius: 0 5px 5px  0;
    width: 70%;
    font-family: Arial, Helvetica, sans-serif;
}
@media screen and (max-width:1130px) {
    .register_form div{
       margin-inline:0 !important;
    }
    .pass_group{
        flex-wrap: wrap;
    }
    .confirm_password, .password_field{
        width: 100% !important;
    }
    .password_field{
        margin-bottom: 30px;
    }
}
@media screen and (max-width:550px) {
    .select_input{
        width:35%;
    }
    
    .phone_input{
        width: 65%;
    }
}

@media screen and (max-width:430px) {
    .select_input{
        width:40%;
    }
    
    .phone_input{
        width: 60%;
    }
}

@media screen and (max-width:268px) {
    .register_btn{
        margin-top: 1.5625rem;
    }
    .register_form{
        padding: 0.125rem;
    }
}