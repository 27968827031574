/* ------------------------------------------------- sec1 card types section----------------------- */

.sec1_gift_card,.sec1_custom_gift_card{
    width: 450px;
    aspect-ratio: 16/9;
    position: relative;
    cursor: pointer;
    border-radius:30px;
}
.front_img{
    width: 100%;
    height: 100%;
    border-radius:30px;
    transition: all 1s;
    border-radius:0.125rem;
}
.sec1_title{
    font-size: 3rem;
    background-color:var(--main_color);
    border-radius: 0px 20px 0 20px;
    padding: 15px;
    color: white;
    opacity: .9;
    font-weight: 400;
}

.sec1_layer{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: all 1s;
    border-radius: 0.3125rem !important;
    overflow: hidden;
}
.back_img{
    width: 100%;
    height: 100%;
    border-radius:30px;
   }
.sec1_gift_card:hover .sec1_layer, .sec1_custom_gift_card:hover .sec1_layer{
    opacity: 1;
}
.sec1_gift_card:hover .front_img{
    opacity: 0;
}

.top_stores{
    padding:1.25rem;
    background-color: white;
    border-bottom: 0.0625rem solid rgb(211, 211, 211);
    margin-bottom: 0.625rem;
}


@media screen and (max-width:1130px){
    .sec1_gift_card,.sec1_custom_gift_card{
        width: 400px;
    }
}
@media screen and (max-width:830px){
    .sec1_gift_card,.sec1_custom_gift_card{
        width: 350px;
    }
}
@media screen and (max-width:768px){
    .sec1_gift_card,.sec1_custom_gift_card{
        width: 500px;
    }
    .sec3_caption h2{
        margin-bottom:5px;
        margin-top: 40px;   
    }
    .sec3_caption{
        text-align: center;    
    }
}
@media screen and (max-width:535px){
    .sec1_gift_card,.sec1_custom_gift_card{
        width:90%;
        margin: auto;
    }
    .caption{
        min-height: 60vh;
        width: 95vw;
        padding-inline:0;
    }
    .sec1_title{
        font-size: 25px;
    }
}