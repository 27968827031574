.modal_container{
    background-color: rgba(255,255,255,.1);
    backdrop-filter: blur(0.1875rem);
}
.modal_body{
    background-color:var(--vanilla) !important;
    border-radius:10px 10px 0 0;
    padding: 3.125rem;
}
.modal_footer{
    display: flex;
    justify-content: space-evenly;
}
.close_btn,.tranfer_btn{
    border-radius: 1.5625rem !important;
    min-width: 6.25rem;
    font-size: 1.125rem;
    font-weight: 700;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5);
    padding: 0.625rem 0.9375rem !important;
}
.tranfer_btn{
    background-color: var(--main_color);
    color: white;
}
.field{
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;
    width: 100%;
    position: relative;
}
.field label{
    font-weight: 400;
    color: var(--dark_white);
    margin-bottom: 0.3125rem;
    text-align: start;
}

.field input{
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.196);  
}
.save_btn{
    background-color: var(--main_color);
    color: white;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.5rem;
    font-weight: 700;
    min-width: 8.75rem;
    transition:all .3s;
}

.save_btn:hover{
    background-color: var(--sub_color);
    transform: translateX(0.3125rem);
}

.select_input{
    border: 1px solid rgba(0, 0, 0, 0.311);
    border-right: 1px solid rgba(0, 0, 0, 0.097);
    border-radius: 5px 0 0 5px;
    width:30% ;
    font-family: Arial, Helvetica, sans-serif;
}
.select_input * {
    border: none !important;
}
.phone_input{
    border-left: none;
    border-radius: 0 5px 5px  0;
    width: 70% ;
    font-family: Arial, Helvetica, sans-serif;
}
.phone_num{
    display: flex;
}
.phone_num_ar{
    flex-direction: row-reverse;
}


@media screen and (max-width:550px) {
    .select_input{
        width:35%;
    }
    
    .phone_input{
        width: 65%;
    }
}
@media screen and (max-width:430px) {
    .select_input{
        width:40%;
    }
    
    .phone_input{
        width: 60%;
    }
}

@media only screen and (max-width: 320px) {
    .select_input {
        width: 50%;
    }
    .btn_group{
        flex-direction: column;
    }
    .save_btn,.later_btn{
        margin: 10px 0;
        width: 50%;
    }
}
@media only screen and (max-width: 370px) {
    .modal_body{
        padding: 10px;
    }
}
@media only screen and (max-width: 250px) {
    .select_input {
        width: 60%;
    }
}

