.orders_body{
    min-height: 90vh;
    padding: 20px;
}

.order_item{
    padding: 1.875rem;
    border-radius: 0.625rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.463);
    margin: 3.125rem 0;
}
.order_item ul{
    padding: 0;
}
.order_item ul li{
    margin-bottom: 0.3125rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 1.0625rem;
    font-family: 'Times New Roman', Times, serif !important;
    word-break: break-all;
}
.order_item_ar ul li{
    font-family: var(--main_font) !important;
    font-size: 1.125rem;
}
.order_item h4{
    font-weight: 600;
}
.arrow_icon_en{
    color: var(--main_color);
    margin-right: 0.625rem;
}
.arrow_icon_ar{
    color: var(--main_color);
    margin-left: 0.625rem;
}
.main_title{
    background-color: var(--main_color);
    padding: 0.625rem;
    color: white;   
    border-radius: 0.625rem;
    text-align: center;
}
.order_item ul{
    padding: 0;
}
.show_more{
    color: var(--sub_color);
    font-weight: 900;
    cursor: pointer;
    transition: all .4s;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.show_more:hover{
    transform: translateY(-2px);
    color: var(--sub_color_light);
}

@media screen and (max-width:320px) {
    .order_item ul li{
       flex-direction: column;
       align-items: flex-start;
       margin: 20px 0;
        }
    .order_item ul li span:nth-of-type(2){
        margin-right: auto;
    }
    .order_item_ar ul li span:nth-of-type(2){
        margin-left: auto;
    }
}

@media screen and (max-width:269px) {
    .order_item{
     width: 100%;
     padding: 10px;
    }
    .order_item ul li{
        word-break: break-all;
    }
    .order_col{
        width: 100%;
        padding: 0 !important;
    }
    .btn_gr{
        flex-direction: column;
    }
   
}


