.main_body,.table_body{
    position: relative;
    padding:2.5rem;
    min-height: 88vh;
    overflow-x: hidden;
}
.paragraphInput {
  border: 1px solid #ccc; /* Light gray border */
  background: white;
  resize: none;
  height: 100px;
  width: 100%;
  font-size: 1rem;
  line-height:2;
  outline: none;
  padding: 8px;
  border-radius: 5px;
}

.paragraphInput[dir="rtl"] {
  text-align: right;
}

.paragraphInput[dir="ltr"] {
  text-align: left;
}


.field{
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    width: 100%;
    position: relative;
}
.field label{
    font-weight: 400;
    color: var(--dark_white);
    margin-bottom: 5px;
    font-size:1.125rem;
}

.field input{
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.196);
    padding:10px;  
}
.save_btn{
    background-color: var(--main_color);
    color: white;
    padding: 10px 15px;
    border-radius: 8px;
    font-weight: 700;
    min-width: 110px;
    border: 0.0625rem solid transparent;
    transition:all .3s;
    min-width: 150px
}
.save_btn:hover{
    background-color:transparent;
    color: var(--main_color);
    border: 0.0625rem solid var(--main_color);
    transform: translateX(5px);
}


/* colors */
.color_square,.color_pro_square{
    width: 6.25rem;
    height: 3.125rem;
    border-radius: 0.3125rem;
    box-shadow:  0 0.0625rem 0.1875rem rgba(0, 0, 0, 0.341);
    margin: 1.25rem 0.625rem;
    position: relative;
    cursor: pointer;
}
.delete_icon{
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%,-50%);
    color: var(--sub_color);
    opacity: 0;
    transition: all .4s;
    font-size: 1.25rem;
    cursor: pointer;
}
.color_square:hover .delete_icon{
    opacity: 1;
}
.color_pro_square:hover .delete_icon{
    opacity: 1;
}

.photo_field{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.photo_label{
    padding: 20px 40px;
    border: 1px solid var(--dark_white);
    border-radius: 10px;
    cursor: pointer;
    transition: all .4s;
}
.photo_label:hover{
    scale: 1.07;
}
.image_preview{
    width: 150px;
    border-radius: 10px;
    cursor: pointer;
}
.photo_label_img{
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.341);
}
.img_icon{
    font-size: 30px;
    color: var(--slate_blue);
}

.color_pro_square img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.3125rem;
}
.crown{
    position: absolute;
    left: 0;
    top: 0;
    color: gold;
    transform: translate(-50%,-50%);
}

/* shapes */
.photo_field{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 30px 0;
    box-shadow:  0 0 3px rgba(0, 0, 0, 0.373);
    border-radius: 10px;
}
.photo_label{
    padding: 20px 40px;
    border: 1px solid var(--dark_white);
    border-radius: 10px;
    cursor: pointer;
    transition: all .4s;
}
.photo_label:hover{
    scale: 1.07;
}
.img_icon{
    font-size: 30px;
    color: var(--slate_blue);
}
.shape_div{
    width: 300px;
    aspect-ratio: 16/9;
    margin: 40px 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.353);
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    background-color: white;
}
.shape_div img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;

}
.shape_div:hover .delete_icon{
    opacity: 1;
}
.shape_crown{
    color: gold;
    position: absolute;
    left: 0;
    top:0;
    transform: translate(-50%,-50%);
    font-size: 1.875rem;
}

.user_control {
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    border-radius: 30px;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 200px;
    color: var(--sub_color);
    transition: opacity 0.4s;
    cursor: auto;
    opacity: 0;
}

.user_control_icon {
    cursor: pointer;
    font-size: 18px;
}

.user_div:hover .user_control {
    opacity: 1;
}

/* shops */
.shop_div{
    width: 150px;
    height: 150px;
    margin: 40px 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.353);
    border-radius:4px;
    position: relative;
}
.shop_div img,.slider_div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius:4px;
}
.checked_store{
    border: 2px solid var(--blue);
}
.shop_div:hover .delete_icon{
    opacity: 1;
}

.disable{
    opacity: .5 !important;
}
.physical_store,.online_store{
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-50%,-50%);
    color: var(--main_color);
}
.online_store{
    color: #198754;
}
.shop_control{
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    border-radius: 30px;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 200px;
    color: var(--sub_color);
    transition: opacity .4s;
    cursor: auto;
    opacity: 0;
}
.shop_div:hover .shop_control{
    opacity: 1;
}
.shop_control_icon{
    transition: all .4s;
    cursor: pointer;
}
.shop_control_icon:hover{
    scale: 1.3;
    color: var(--main_color);
}
.category_label{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.category_label img{
    margin-inline: 10px;
    width: 50px;
}

.form_check_label{
    padding: 5px 10px;
    background-color: white;
    color: black !important;
    border-radius: 10px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.266);
    cursor: pointer;
    transition: all .4s;
    min-width: 120px;
}
.form_check_label:hover{
    scale: 1.1;
}
.active_input{
    background-color: var(--sub_color);
    color: white !important;
}
.form_check_group{
   flex-direction: row;
}
.textArea{
    height: 200px;
}
/* coupon */
.modal_container{
    background-color: rgba(0, 0, 0, 0.258);
    backdrop-filter: blur(0.1875rem);
}
.modal_body{
    border-radius:0.625rem 0.625rem 0 0;
}
.cop_div{
    padding: 1.875rem;
    border-radius: 0.625rem;
    border: 0.0625rem solid rgba(0, 0, 0, 0.392);
    margin: 1.875rem 0;
    box-shadow: 0.0625rem 0.0625rem 0.1875rem rgba(0, 0, 0, 0.353);
}
.cop_content ul{
    padding: 0;
}

.cop_content ul li{
    margin: 0.3125rem 0;
}

/* configs */
.select_shape{
    width: 6.25rem;
}
.select_shape img{
    width: 100%;
}
.configs_body{
    width: 70%;
    margin: auto;
}

/* users */

.field{
    margin-bottom: 3rem;
    text-align: start;
    font-weight: 600;
    position: relative;
}

.register_form{
    padding:15px 20px;
    width: 100%;
    text-align: center;
    overflow: hidden;
}
.register_form label{
    text-align: start;
    margin-bottom: 3px;
}
.register_btn{
    width: 80%;
    background-color: var(--main_color);
    color: white;
    padding: 10px 15px;
    border-radius: 15px;
    margin: 10px 0;
    border: 0.0625rem solid transparent;
    font-weight: 600;
    transition: all .3s;
}
.register_btn:hover{
    background-color:transparent;
    color: var(--main_color);
    border: 0.0625rem solid var(--main_color);
}
.confirm_password, .password_field{
    width: 50%;
}
.have_acc_p{
    letter-spacing: 0.0625rem;
}
.phone_num{
    display: flex;
}
.ar_phoneNum{
    flex-direction: row-reverse;
}
.select_input{
    border: 0.0625rem solid rgba(0, 0, 0, 0.311);
    border-right: 0.0625rem solid rgba(0, 0, 0, 0.097);
    border-radius: 0.3125rem 0 0 0.3125rem;
    width:20%;
    font-family: 'Times New Roman', Times, serif;
}
.select_input * {
    border: none !important;
}
.phone_input{
    border-left: none;
    border-radius: 0 0.3125rem 0.3125rem  0;
    width: 80%;
    font-family: Arial, Helvetica, sans-serif;
}

.user_div{
    padding: 1.25rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.466);
    margin: 1.875rem 0;
    border-radius: 0.625rem;
    min-width: 240px;
    position: relative;
}
.user_div:hover .delete_icon{
    opacity: 1;
}
.user_header{
    display: flex;
}
.user_header_info{
    margin-inline: 0.625rem;
}
.user_header_info h5{
    margin: 0 !important;
    padding: 0 !important;
    font-weight: 600;
}
.user_header_info span{
    margin: 0 !important;
    padding: 0 !important;
    word-break: break-word;
}
.user_img{
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 50%;
}
.user_img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
.details_list{
    word-break: break-word;
}
.details_list_icon{
    color: var(--slate_blue);
    margin-inline: 0.3125rem;
}
.filter_box{
    border: 1px solid transparent;
    border-radius: 0.1875rem;
    padding: 0.3125rem 0.5rem;
    cursor: pointer;
    background-color: var(--main_color);
    color: var(--white);
    font-weight: 600;
    transition: all .4s;
}
.filter_box:hover svg{
    cursor: pointer;
    transition: all .3s;
}
.filter_box:hover{
   background-color: white;
   color: var(--main_color);
   border: 1px solid var(--main_color);
}
.filter{
    font-size: 1.125rem;
}


/* wallet */
.cur_pointer{
    cursor: pointer;
}

/* statitics */
.statics_body{
    min-height: 100vh;
    position: relative;
}
.chart_div{
    width: 100%;
    height: 600px;
    margin-top: 30px;
}
.static_section{
    margin:30px;
}
.section_row{
    background-color: rgba(218, 237, 247, 0.355);
    padding: 0.625rem;
    border-radius: 0.625rem;
    box-shadow:  0 1px 3px rgba(0, 0, 0, 0.362);
}
.thrd_sec_div{
    text-align: center;
    margin: 1.875rem;
}
.thrd_sec_div h4{
    font-family: 'Times New Roman', Times, serif !important;
    margin: 0;
}
.thrd_sec_icon{
    font-size: 3.4375rem;
    color: var(--slate_blue);
    margin-bottom: 0.625rem;
}
.download_icon{
    font-size: 16px;
    color: var(--slate_blue);
    cursor: pointer;
    margin-inline: 10px;
}

/* discount */
.container_body{
    min-height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0.625rem 0;
}
.title{
    font-size: 1.125rem;
    color: var(--dark_white);
    display: flex;
    align-items: center;
    justify-content: center;
}
.plus_icon{
    color: green;
    margin-inline: 0.625rem;
}
.integer{
    font-size: 6.25rem;
    font-family: 'Times New Roman', Times, serif !important;
}
.decimal{
    font-size: 40px;
    font-family: 'Times New Roman', Times, serif !important;

}
.sar{
    font-size: 1.875rem;
}
.name{
    font-size: 3.4375rem;
    text-align: center;
}
.profile_img{
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    margin: auto;
}
.profile_img img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.list_item{
    padding: 1.875rem;
    background-color: var(--sub_color);
    box-shadow: 0 0 0.1875rem black;
    margin: 1.875rem 0;
    min-width: 12.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1.25rem;
    cursor: pointer;
    transition: all .4s;
}

.list_item span{
    font-size: 1.4375rem;
    font-weight: 500;
    color:white
}
.list_item_icon{
    color: var(--sub_color);
    font-size: 2.1875rem;
    margin-bottom: 0.625rem;
    transition: all .4s;
    color:var(--main_color)

}

.list_item:hover .list_item_icon{
    color: white;
}


/* acc settingg */

.acc_setting_body{
    padding: 1.25rem;
    overflow-y: auto;
    scrollbar-color: transparent transparent ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form_container{
    padding: 1.25rem;
    border-radius: 0.625rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.284);
    margin-bottom: 1.25rem;
    width: 80%;
}
.verify_container{
    box-shadow: 0 0 0.1875rem yellow;
}

/* all discounts  */
.discount_body{
    min-height: 90vh;
    padding: 2.5rem;
    overflow-x: hidden;
}
.disc_content{
    padding: 1.875rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.263);
    margin-bottom: 2.5rem;
    border-radius: 0.625rem;
}
.red_shadow{
    box-shadow: 0 0 0.1875rem var(--main_color);
}
.disc_content ul{
    padding: 0;
}
.disc_content ul li{
    padding: 0.3125rem;
}
.list_icon{
    color: var(--slate_blue);
}

/* docs */
.docs_body{
    min-height: 85vh;
    padding: 1.25rem;
}
.new_font{
    font-family: 'Times New Roman', Times, serif !important;
}
.request_api{
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.2);
    color: var(--main_color);
    padding: 0.625rem 1.25rem;
    border-radius: 0.3125rem;
    margin: 0.625rem;
    background-color: aliceblue;
}

.request_body{
    background-color: aliceblue;
    padding: 1.25rem;
    margin: 0.625rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.2);
}
.request_body ul li span{
color: var(--main_color);
}
/* designs */
.banner_img_label,.web_sub_banner_label,.mob_sub_banner_label{
    border-radius: 10px;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.532);
}
.banner_img_label img,.web_sub_banner_label img,.mob_sub_banner_label img{
    width: 350px;
    aspect-ratio: 16/9;
    border-radius: 10px;
    cursor: pointer;
}
.web_sub_banner_label img{
    aspect-ratio: 3/1;
}
.mob_sub_banner_label img{
    aspect-ratio: 3/2;
}

/* slider */
.slider_div{
    width: 200px;
    height: 112.5px;
    margin: 40px 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.353);
    border-radius:4px;
    position: relative;
}
.slider_div:hover .shop_control,.color_pro_square:hover .shop_control,.shape_div:hover .shop_control{
    opacity: 1;
}



/* main */
.show_small_screens{
    display: none;
   }
   .blue_border{
    border: 2px solid var(--sub_color_light);
   }

@media screen and (max-width:1130px) {
    .register_form div{
       margin-inline:0 !important;
    }
    .pass_group{
        flex-wrap: wrap;
    }
    .confirm_password, .password_field{
        width: 100% !important;
    }
    .password_field{
        margin-bottom: 30px;
    }
}


@media screen and (max-width:768px) {
    .chart_div{
        height: 800px;
        color: rgb(201, 201, 201);
    }
}
@media screen and (max-width:680px) {
    .configs_body{
        width: 98%;
        margin: auto;
    }
    .cart_tbody{
        word-break: break-all;
    }
    .table_body{
        padding: 3px;
    }
}

@media screen and (max-width:550px) {
    .select_input{
        width:35%;
    }
    .phone_input{
        width: 65%;
    }
    .hidden_mobile{
    display: none;
    }
    .show_small_screens{
        display: block;
    }
}
@media screen and (max-width:470px) {
    .banner_img_label img,.web_sub_banner_label img,.mob_sub_banner_label img{
        width: 200px;
    }
}

@media screen and (max-width:430px) {
    .select_input{
        width:40%;
    }
    
    .phone_input{
        width: 60%;
    }
}

@media screen and (max-width:400px) {
    .cop_div {
        width: 98%;
        
    }
    .main_body{
        padding:5px;
    }
    .user_div{
        min-width:98%;
    }
    .shape_div{
        width: 90%;
    }
    .docs_body{
        padding: 5px;
    }
    .request_api{
        padding: 10px;
        margin: 10px 0;
        word-break: break-all;
    }
    .request_body{
        padding: 10px;
        margin: 10px 0;
        word-break: break-all;
    }
}

@media screen and (max-width:268px) {
    .register_btn{
        margin-top: 1.5625rem;
    }
    .register_form{
        padding: 0.125rem;
    }
    .banner_img_label img,.web_sub_banner_label img,.mob_sub_banner_label img{
        width: 150px;
    }
}