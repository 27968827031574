.filter_box{
    border: 1px solid transparent;
    border-radius: 0.1875rem;
    padding: 0.3125rem 0.5rem;
    cursor: pointer;
    background-color: var(--main_color);
    color: var(--white);
    font-weight: 600;
    transition: all .4s;
}
.filter_box:hover svg{
    cursor: pointer;
    transition: all .4s;
}
.filter_box:hover{
   background-color: white;
   color: var(--main_color);
   border: 1px solid var(--main_color);
}
.filter{
    font-size: 1.125rem;
}

.controllers_icon{
    position: absolute;
    top:-0.5rem;
    right:-0.625rem;
    display: flex;
}
.controllers_icon_ar{
    position: absolute;
    top:-0.625rem;
    left:-0.9375rem;
    display: flex;
}

.controllers{
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.221);
    padding-bottom: 0.625rem;
    margin-inline: 0.625rem;
}

@media screen and (max-width:480px) {
    .controllers{
        flex-direction: column-reverse;
    }
    .search_field{
        margin-bottom: 10px;
    }
}
