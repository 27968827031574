.update_form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }
  