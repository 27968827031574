/* ------------------------------------------------- sec2 quality section----------------------- */
.section_title{
    overflow-x: hidden;
}
.sec2_quality_img{
    width: 600px;
    height: 300px;
    border-radius: 5px; 
}
.sec2_quality_img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.3125rem;
}

/* ------------------------------------------------- sec3 quality section----------------------- */

.sec3_caption h2{
    margin-bottom: 48px;
    font-size:3rem;
}
.sec3_caption p{
    font-size:1.25rem;
    line-height: 1.2;
    color: rgba(0, 0, 0, 0.832);
    font-weight: 400;
}

/* ------------------------------------------------- sec4 caption section----------------------- */
.caption{
    background-size: cover;
    background-position: 50% 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 55vh;
    width: 85vw;
    border-radius: 0.625rem;
    padding-inline:3.125rem;
    margin: auto;
    margin-bottom: 3.125rem;
    text-align: center;
}

.caption_text{
    color: white;
    background-color: #494361e7;
    padding: 1rem 0;
    border-radius: 1.25rem;
    margin-top: 20px;
}
.caption_text h2{
    font-size: 4.5rem;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom:10px;
}
.caption_text p{
    font-size: 1.875rem;
    font-weight: 300;
    text-transform: capitalize;
    margin: 0 ;
    padding: 0;
    letter-spacing: 0;
}


@media screen and (max-width:992px){
    .sec3_caption{
        margin-top: 40px;  
    }
}
@media screen and (max-width:768px){
    .sec3_caption h2{
        margin-bottom:5px;
        margin-top: 40px;   
    }

}
@media screen and (max-width:602px){
    .sec2_quality_img{
        width: 99% !important;
    }
}
@media screen and (max-width:535px){
    .caption{
        min-height: 60vh;
        width: 95vw;
        padding-inline:0;
    }
    .caption_text h2{
        font-size: 3.125rem;
    }
    .caption_text p{
        font-size: 1.25rem;
    }
}
