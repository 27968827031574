.header_details{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    font-family: "Cairo", sans-serif !important;
}
.min_width_fixed{
    min-width: 700px;
}
.qr_code_img{
    width:150px;
    margin: auto;
}
.order_ul{
    padding: 0;
    margin: 0;
    width: 75%;
    margin: auto;
}
.order_ul li{
    font-size: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.244);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-weight: 600;
    color: var(--dark_white);
}
.order_ul li span{
    font-weight: 700;
}
.item_header{
    font-size: 22px !important;
    font-weight: 600 !important;
    border-top: 1px solid rgba(0, 0, 0, 0.244);
    color: rgba(0, 0, 0, 0.856) !important;
}

@media screen and (max-width:750px) {
    .min_width_fixed{
        min-width:90%;
    }
}