.main_nav{
    transition: all .5s;
    padding-top: 15px;
    padding-bottom: 15px;
 }
 .new_nav{
     background-color: rgba(223, 221, 221, 0.703);
     backdrop-filter: blur(2px);
     box-shadow: 0 0 3px rgba(0, 0, 0, 0.189);
     width: 100vw !important;
 }
 .new_nav .nav_list li a{
     color: black !important;
 }
 .new_nav .cart_icon, .new_nav .lang_item{
     color: black !important;
 }
 .nav_list li a,.lang_item{
     color: var(--white) !important;
     font-weight: 500;
     font-size: 1.25rem;
     transition: all .3s;
     position: relative;
     cursor: pointer;
 }
 
 .nav_list li a::after{
     content: '';
     width:0;
     height:0.0625rem;
     background-color: var(--main_color);
     position: absolute;
     bottom: 0;
     left: 0;
     transition: all .3s;
 }
 .nav_list li a:hover::after{
     width:  100%;
 }
 .nav_list li a:hover,.lang_item:hover{
     color: var(--main_color) !important;
 }
 .new_nav .nav_list li a:hover{
     color: var(--main_color) !important;
 }
 
 .nav_list li a.active,.new_nav .nav_list li a.active{
     position: relative;
     color: var(--main_color) !important;
 }
 
 .nav_list li a.active::before,.new_nav .nav_list li a.active::before{
     content: '';
     width:0;
     height:0.0625rem;
     background-color: var(--main_color);
     position: absolute;
     bottom: 0;
     left: 0;
     transition: all .3s;
 }
 
 .brand{
     width:120px;
     cursor: pointer;
 }
 .admin_brand{
    width:150px;
    cursor: pointer;
 }
 .burger_list{
     width: 2.5rem;
     height: 1.5625rem;
     cursor: pointer;
     display: none;
 }
 
 .half_line{
     width: 50%;
     height: 0.1875rem;
     background-color: var(--white);
     transition: width .3s;
 }
 
 .full_line{
     width: 100%;
     height: 0.1875rem;
     background-color:var(--white);
 }
 .new_nav .half_line,.new_nav .full_line{
     background-color: black !important;
 }
 .burger_list:hover .half_line{
     width: 100%;
 }
 
 .list:hover{
     transform: scale(1.1);
 }
 
  .burger_list_white .half_line, .burger_list_white .full_line{
     background-color: var(--white) !important;
 }
 
 .cart_icon{
     font-size:1.5625rem;
     color: var(--white);
     font-weight: 700;
     cursor: pointer;
     transition: all .3s;
 }

 .cart_icon:hover{
     scale: 1.08;
 }

 .active_lang span{
     background-color: var(--sub_color);
     color: var(--white);
 }
 .active_lang span:hover{
     background-color: var(--sub_color);
     color: var(--white);
 }
 .user_icon{
     font-size: 0.9375rem;
     color: white;
     transition: all .6s;
 }
 .profile_img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    cursor: pointer;
    transition: scale .4s;
 }
 .profile_img img{
     width: 100%;
     height: 100%;
     object-fit: cover;
     border-radius: 50%;
 }
 .profile_img:hover{
     scale: 1.04;
 }
 
 .nav_controllers{
     display: flex;
     justify-content: center;
     align-items: center;
 }
 
 .new_pages_nav .nav_list li a{
    color: black !important;
 }
 .smallBrand{
    display: none;
 }
.lang_item{
    font-family: "Cairo" !important;
}
.special_view_language{
    display: none;
    margin: 0;
    
}
.special_view_language li{
    color: black !important;
}

 @media screen and (max-width:1150px) {
     .list{
         display: flex;
     }
     .special_hidden{
         display: none;
     }
     .nav_list{
         width: auto;
     }
     .special_view_language{
        display: inline;
     }
     .brand{
        width: 7.5rem !important;
    }
    .brand img{
        width: 7.5rem !important;
    }
 }
 
 @media screen and (max-width:768px) {
     .brand{
        width: 8.75rem;
    }
    .brand img{
        width: 8.75rem !important;
    }
    .sign_btn{
        display: none ;
    }
 }

 @media screen and (max-width:650px) {
    .brand{
        width: 8.125rem;
    }
    .brand img{
        width: 8.125rem !important;
    }

 }

 @media screen and (max-width:450px) {
    .profile_img_link{
        display: none;
    }
 }
 
 @media screen and (max-width:430px) {
     .brand{
        width: 7.5rem !important;
    }
    .brand img{
        width: 7.5rem !important;
    }

 }
 @media screen and (max-width:375px) { 
    .brand{
        width: 5rem !important;
    }
    .brand img{
        width: 5rem !important;
    }
 }
 @media screen and (max-width:355px) { 
     .main_nav{
         justify-content: center;
         padding-top: 20px;
      }
      .admin_brand{
        width: 100px;
      }
      .admin_brand img{
        width: 100px !important;
      }
      .profile_img{
        width: 35px;
        height: 35px;
     }
     .controller_div{
        margin: 0 !important;
     }
     .burger_list{
        margin: 0 !important;
     }
     .wallet_icon{
        display: none;
     }


 }
 
 @media screen and (max-width:319px) {
    .loggedBrand{
        display: none;
    }
    .smallBrand{
        display: block;
        width: 5rem !important;
    }
    .smallBrand img{
        width: 5rem !important;
    }
    .nav_controller_small_ar{
        width: 100vw;
        padding-left: 100px;
    }
    .nav_controller_small_en{
       width: 100vw;
       padding-right: 100px;
    }
    .controller_div{
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
 }
 @media screen and (max-width:290px) {
    .special_view_language{
        display: none;
    }
 }
 @media screen and (max-width:267px) {
    .admin_brand{
        width:60px;
      }
      .admin_brand img{
        width: 60px !important;
      }
 }


