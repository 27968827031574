.canva_body {
    text-align: center;
    margin: 50px 0;
    padding-bottom:50px;
  }
  .content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  .card_side_container{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 50px;
    position: relative;
  }
.control_side_container{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.carousel_body{
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 40px;
  margin-top: 1.5625rem;
}

  .card_side_header{
    position: absolute;
    left:0;
    top: 0;
    color: black;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    width: 100%;
    padding: 0.625rem 1.875rem;
  }
  
  .card {
    height: auto;
    width: 100%;
    border-radius: 30px;
    overflow: hidden;
    box-shadow:  0 1px 0.3125rem rgba(0, 0, 0, 0.204);  
  }

  .logoThumb{
    width: 5rem;
    margin: 1.875rem 2.5rem;
  }
  .choose_color{
    overflow-x: hidden;
  }
  .title{
    font-size: 34px;
  }
  .color_circle{
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin: 0.625rem 0;
    box-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.916);
    cursor: pointer;
    transition: all .4s;
  }
  .color_circle:hover{
    scale: 1.08 ;
  }
  .color_group{
    margin: 30px 0;
    width: 80%;
    margin: auto !important;
  }

  .choose_shape{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
    
  .shapes_container,.logo_container{
        justify-content: center;
        overflow-x: hidden;
        width: 100%;
  }
    .shape_div{
        Width:6.25rem; 
        margin: 20px 0;
        cursor: pointer;
        transition: all .4s;
        position: relative;
    }

    .shape_div img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .logo_div{
        width: 6.25rem;
        height: 6.25rem;
        border-radius: 50%;
        cursor: pointer;
        transition: all .4s;
        margin: 1.25rem 0;
        box-shadow:  0 0 3px black;
    }
    .logo_div img{
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .text_container div input{
      background-color: transparent !important;
      border: 0.0625rem solid rgba(0, 0, 0, 0.262);
    }

    .color_input{
      width:0 !important;
      height: 0 !important;
      opacity: 0;
      position: absolute;
      z-index: -5;
    }
    .input_color_show{
      width: 4.375rem;
      height: 30px;
      background-color: var(--main_color);
      border: none;
      outline: none;
    }
    .color_input_div{
      background-color: var(--main_color);
      color: white;
      padding: 0.3125rem;
      width: 6.25rem;
      border-radius: 0.625rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.25rem;
      overflow: hidden;
      cursor: pointer;
    }
    .color_input_div label{
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .fontSize_input{
      width: 12.5rem;
      margin-inline: 0.625rem;
    }
    .fontSize_input div{
      color: white ;
    }

    .control_inputs_div{
      display: flex;
      align-items: center;
      justify-content: end;
      width: 100%;
      margin: 50px 0;
    }
    .text_container{
      height: 12.5rem;
    }
    .message_container{
      height: 100%;
      width: 100%;
      margin: auto;
    }

    .message_container h4,.text_container h4{
      background-color: var(--main_color);
      color:white;
      padding: 10px;
      max-width: 200px;
      border-radius: 10px 2px 10px 2px;
      margin: 10px auto;
      text-align: center;
    }
    .text_input{
      background-color: transparent;
      margin-bottom: 1.25rem;
      width: 100%;
      height: 85% !important;
      color: #000 !important;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.219);
      border:none !important;
    }
    .text_input:focus{
      background-color: transparent !important;
    }
    .text_containers_parent{
      padding: 3.125rem;
      width: 60%;
      margin: auto;
    }
    .price_input{
      box-shadow: 0 0rem 0.1875rem rgba(0, 0, 0, 0.219);
      padding: 1.25rem;
      width: 100%;
      height: 4.375rem;
      border-right: none !important;
    }
    
    .sar_span{
      color:black !important;
      border: 1px solid rgba(0, 0, 0, 0.277) !important;
      border-left: none !important;
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.219);
    }
    .steps_indicator {
      display: flex;
      justify-content: space-around;
      margin: 1.25rem 0 0.3125rem 0;
    }
    
    .step {
      font-size: 0.875rem;
      padding: 0.625rem 2.5rem;
      text-align: center;
      color: #ccc;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .step_num{
      background-color: rgba(128, 128, 128, 0.416);
      color: white;
      border-radius: 50%;
      padding: 0.625rem;
      width:1.375rem;
      height:1.375rem;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 0.1875rem;
      font-family: 'Times New Roman', Times, serif;
    }
    .active_step .step_num{
      background-color:#41547B;
    }
    .step_num span{
      font-size: 0.625rem !important;
    }
    .active_step {
      color: #000;
    }
    
    .step span {
      font-size: 16px;
    }

    .color_pro_square img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius:50%;
  }
  .crown{
      position: absolute;
      left: 0;
      top: 0;
      color: gold;
      transform: translate(-40%,-40%);
  }
  .slider {
    width: 100%;
    margin: 10px 0;
  }
  .shape_crown{
    color: gold;
    position: absolute;
    left: 0;
    top:0;
    transform: translate(-50%,-50%);
}
.shape_scale_div{
   width: 100%;
   display: flex;
}
.shape_input{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;    
}

    @media screen and (max-width:992px) {
      .canva_body {
        height: auto;
        margin: 30px 0;
      }
      .card_side_container{
        margin: 30px 0;
      }
      .text_containers_parent{
        width: 100%;
        padding: 5px;
      }
      .shape_scale_div{
        justify-content: center !important;
     }
    }

    @media screen and (max-width:450px) {
      .step {
        padding: 10px;
      }
      .control_inputs_div{
        justify-content: center;
      }
    }
    @media screen and (max-width:430px) {
      .logo_div{
        width: 5rem;
        height: 5rem;
    }
    }
    @media screen and (max-width:350px) {
      .shape_scale_div{
        flex-direction: column;
        align-items: center;
      }
    }
    @media screen and (max-width:335px) {
      .logo_div{
        width: 12.5rem;
        aspect-ratio: 1/1;
    }
      .logo_container{
        flex-direction: column;
        align-items: center !important;
        width: 100%;
        padding: 0 !important;
      }
      .control_inputs_div{
       flex-direction: column;  
      }
      .fontSize_input{
        margin: 10px;
      }
    }
    @media screen and (max-width:260px) {
      .card_side_header{
       display: none;
      }
      .steps_indicator{
        flex-wrap:wrap;
      }
      .color_group{
        width: 100%;
      }
    }


