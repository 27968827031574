.top_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--main_color);
  opacity: 0.5;
  position: fixed;
  bottom: 1.25rem;
  right: 0.9375rem;
  padding: 0.5rem 0.625rem;
  border-radius: 0.3125rem;
  color: white;
  font-size: 1.25rem;
  z-index: 2;
  backdrop-filter: blur(5px);
  transition: all 0.3s;
}
.top_btn:hover {
  transform: translateY(-10px);
  opacity: 1;
}
