.user_login_container{
    width: 100vw;
    min-height: 92vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.25rem;
    position: relative;
    overflow-x: hidden;
}
.user_login_content{
    display: flex;
    justify-content: center;
    min-width: 90%;
    overflow: hidden;
}
.user_login_form{
    min-height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.user_login_form h3{
    font-size: 2.5rem;
}   


@media screen and (max-width:1065px) {
    .user_login_content{
        width: 100%;
    }
}

@media screen and (max-width:800px) {
    .user_login_form{
        width:99%;
    }
}

@media screen and (max-width:650px) {
    .user_login_container{
        padding: 0.625rem;
    }
    .user_login_form{
        padding: 0.625rem;
    }
}

@media screen and (max-width:420px){

.user_login_form
{
    width: 100%;
}
}
@media screen and (max-width:215px){
.user_login_container{
    padding-inline: 0;
}    
}
