.register_container{
    width: 100vw;
    min-height: 92vh;
    padding: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}
.register_content{
    display: flex;
    justify-content: center;
    min-width: 98%;
    overflow: hidden;
}

.register_form{
    width: 50%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.register_form h3{
    font-size:2.5rem;
    text-align: center;
}


@media screen and (max-width:1063px) {
    .register_content{
        width: 100%;
    }
}
@media screen and (max-width:800px) {
    .register_form{
        width:99%;
    }
}
@media screen and (max-width:500px) {
    .register_caption{
        display: none;
    }
    .register_form{
        width: 100%;
        padding-bottom: 0;
    }
}

