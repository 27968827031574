.flag_div{
    width:100%;
    margin-right: 0.3125rem;
    padding-right: 0.625rem;
}
.flag_div img{
    width: 28px;
}



@media screen and (max-width:280px) {
    .flag_div img{
        width: 25px;
    }
}