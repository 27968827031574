
.card_body{
    border: 0.0625rem solid rgba(0, 0, 0, .1);
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.238);
    border-radius: 30px;
 }

 .card_stage{
     box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.159);
     border-radius: 30px;
     overflow: hidden;
 }

.field{
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;
    width: 100%;
    position: relative;
}
.field label{
    font-weight: 400;
    color: var(--dark_white);
    margin-bottom: 0.3125rem;
    font-size: 20px;
}

.field input{
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.196);  
}
.name_input{
    height: 70px;
    border-radius: 2px !important;
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.save_btn{
    background-color: var(--main_color);
    color: white;
    padding: 0.625rem 0.9375rem;
    border-radius: 0.5rem;
    font-weight: 700;
    min-width: 8.75rem;
    transition:all .3s;
}
.later_btn{
    padding: 0.625rem 0.9375rem ;
    border-radius: 0.5rem;
    font-weight: 700;
    min-width: 8.75rem;
}
.save_btn:hover{
    background-color: var(--sub_color);
    transform: translateX(0.3125rem);
}

.user_general_info .field{
    margin-bottom: 1.875rem;
}

.phone_num{
    display: flex;
    align-items: center;
    background-color: white;
    height: 70px;
    border: 1px solid rgba(0, 0, 0, 0.425);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}
.ar_phoneNum{
    flex-direction: row-reverse;
}
.select_input{
    border: none !important;
    width:20%;
    font-family: Arial, Helvetica, sans-serif;
}

.phone_input{
    border-radius: 0 5px 5px  0;
    width: 80%;
    font-family: Arial, Helvetica, sans-serif;
    color: black;
    height: 100%;
    border: none !important;
}
.select_input * {
    border: none !important;
}
.crown_icon{
    color: gold;
}
.info{
    margin-inline: 5px;
    cursor: pointer;
}
@media screen and (max-width:1200px) {
 .rec_row{
    flex-direction: column-reverse;
 }
}

@media screen and (max-width:600px) {
    .field h4{
        margin: 0;
        text-align: center;
    }
    .select_input{
        width:30%;
    }
    
    .phone_input{
        width: 70%;
    }
}
@media screen and (max-width:430px) {
    .select_input{
        width:40%;
    }
    
    .phone_input{
        width: 60%;
    }
}

@media only screen and (max-width: 370px) {
    .save_btn,.later_btn {
        width: 40%;
    }
}

@media only screen and (max-width: 320px) {
    .select_input {
        width: 50%;
    }
    .btn_group{
        flex-direction: column;
    }
    .save_btn,.later_btn{
        margin: 10px 0;
        width: 50%;
    }
}
@media only screen and (max-width: 250px) {
    .select_input {
        width: 60%;
    }
    .phone_input img{
        width: 40%;
    }
}

