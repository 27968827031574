
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
:root{
  --main_font:"Roboto", sans-serif;
  --main_color:#b62026;
  --deep_main_color:#650E14;
  --white:rgb(238, 237, 237);
  --sub_color:#222A40;
  --sub_color_light:#41547B;
  --blue:#1a429d;
  --dark_white:rgb(129, 129, 129);
  --main_color_lighter:rgba(228, 181, 181, 0.3);
  --main_bg_color:#FEF7EF;
  --slate_blue:#6A7E8C;
  --ivory_color:#f5f5d7;
  --dusty_Gold:#C2A14D;
  --natural:#DCDDDD;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden !important;
  background-color: var(--main_bg_color);
  font-family:var(--main_font) !important;
}

a{
  text-decoration: none !important;
  color: inherit !important;
}

button{
  outline: none;
  border: none;
  background-color: transparent;
}
input{
  outline: none;
  padding:8px 10px;
  border: 1px solid rgb(180, 180, 180);
  border-radius: 10px;
  transition: all .4s;
}
input:focus{
  border: 1px solid var(--main_color);
  box-shadow: 0 0 5px rgba(0, 110, 255, 0.8);
}
input[type="search"]::-webkit-search-cancel-button{
  display: none;
}

.mini_word{
  font-size: 13px !important;
  color: var(--dark_white) !important;
  font-weight: 500;
}

.special_main_color{
  color: var(--main_color);
}

ul li{
  list-style: none;
}
.main_shadow{
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.266);
}
.page_height{
  min-height: 100vh;
  position: relative;
}
.bg-main {
  background-color: var(--main_color);
}
.or_span{
  position: relative;
  text-align: center;
}
.or_span::after{
  content: "";
  width: 12.5rem;
  height: 0.0625rem;
  background-color: rgb(202, 202, 202);
  position: absolute;
  top: 50%;
  left:1.25rem;
}
.or_span::before{
  content: "";
  width: 12.5rem;
  height: 0.0625rem;
  background-color: rgb(202, 202, 202);
  position: absolute;
  top: 50%;
  right:1.25rem;
}
.disabled{
  pointer-events: none;
  opacity: 0.4;
}
.ellipsis {
  overflow: hidden; 
  white-space: nowrap;
  text-overflow: ellipsis;
}
.main_shadow{
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.315) !important;
  background-color: red !important;
}

.nav-item .active{
  background-color: transparent !important;
  color: var(--blue)!important;
  font-weight: 700 !important;

}
.nav-link{
  color: black !important;
  font-weight: 600 !important;
}
.rmdp-container{
  text-align: center;
}
.rmdp-input{
  font-size:1.875rem;
  padding: 2.5rem 1.25rem !important;
  text-align: center;
  letter-spacing: 0.125rem;
  font-weight: 500;
  font-family:Arial, Helvetica, sans-serif;
  width: 100%;
}

.carousel-control-prev-icon{
  position: absolute;
  bottom: 0 !important;
  right:0 !important;
  cursor: pointer;
}
.carousel-control-next-icon{
  position: absolute;
  bottom: 0 !important;
  left: 0 !important;
  cursor: pointer;
}
.carousel-control-prev{
  height: 30px !important;
  width: 30px !important;
  margin-top: auto;  
  margin-left: 20px !important;

}
.carousel-control-next{
  height:30px !important;
  width: 30px !important;
  margin-top: auto;
  margin-right: 20px !important;
}
textarea{
  background-color: white !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.FontFamily__control,.fontSizes__control{
  background-color: var(--main_color) !important;
  color: white !important;
}
#react-select-2-placeholder,.fontSizes__single-value{
  color: white !important;
  z-index: 3;
  font-size: 1.125rem;
}
#react-select-3-placeholder,.FontFamily__single-value{
  color: white !important;
  z-index: 3;
  font-size: 1.125rem;
}
.FontFamily__option,.fontSizes__option{
  background-color: white !important;
  color: black !important;
}
.height_container{
  min-height: 85vh;
}
@media screen and (max-width:768px) {
  body,html{
    font-size: 14px;
  }
}

@media screen and (max-width:305px) {
  .rmdp-input{
    font-size:1.25rem;
    padding: 1.25rem 0.625rem !important;
    letter-spacing: normal;
  }
}

