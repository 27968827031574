
/* ____________________________btn one______________________________________ */

.MainButton,.MainButton_white,.MainButton_blue{
    padding: 0.5rem 0.5625rem;
    border-radius: 0.3125rem;
    color: rgb(238, 238, 238);
    background-color: var(--main_color);
    min-width: 8.75rem;
    font-size: 1.0625rem;
    font-weight: 600;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.5);
    overflow: hidden;
    transition: transform .3s;
    position: relative;
}

/* button with white bg */
.MainButton_white{
    background-color: white;
    color: black;
}
.MainButton_blue{
    background-color: var(--sub_color);
    color: white;
}
.MainButton_layer,.MainButton_blue .MainButton_layer{
    background-color: white;
    width: 1.25rem;
    height:150%;
    position: absolute;
    left: 0;
    top:50%;
    transform: translate(-50%,-50%);
    rotate: 15deg;
    opacity: 0;
    transition: all .3s;
}
.MainButton_white .MainButton_layer{
    background-color: var(--main_color_lighter);
}

.MainButton:hover .MainButton_layer,.MainButton_white:hover .MainButton_layer,.MainButton_blue:hover .MainButton_layer{
    animation-name: buttonMovement;
    animation-duration: .3s;
    animation-direction: alternate;
}

.MainButton:hover,.MainButton_white:hover,.MainButton_blue:hover{
    transform: scale(1.1);
}

@keyframes buttonMovement {
    0%{opacity: 0;left: 0;}
    99%{opacity: 1;left: 100%;}
    100%{opacity: 0;}
}

/* ____________________________btn two______________________________________ */

.btn_two{
    padding: 0.75rem 1.25rem;
    border-radius: 0.1875rem;
    font-weight: 700;
    background-color: var(--dusty_Gold);
    min-width: 12.5rem;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.266);
    color: white;
    font-size: 1.375rem;
    transition: all .4s;
}
.btn_two:hover{
    scale: 1.07;
    background-color: var(--sub_color);
}

/* ____________________________btn three______________________________________ */

.btn_three{
    padding: 0.5rem 1.25rem;
    border-radius:0.5rem;
    background-color: var(--main_color);
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.266);
    color: white;
    transition: transform .4s;
}
.btn_three svg{
    margin-left: 0.625rem;
}

.btn_three:hover{
    transform: translateX(5px);
}
