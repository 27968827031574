.card_container{
    display: flex;
    justify-content: center;
    min-height: 100vh;
    margin-bottom: 10px;
    overflow-x: hidden !important;
}
.card_content{
    width:100%;
}
.card_body{
    border: 0.0625rem solid rgba(0, 0, 0, .1);
    border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.238);
    border-radius: 1.875rem;
    margin-inline: 1.25rem !important;
 }

 .card_stage{
     box-shadow: 0 0 3px rgba(0, 0, 0, 0.159);
     border-radius: 1.875rem;
     overflow: hidden;
     width: 100%;
 }
 
 .btns_group{
     display: flex;
 }
 .responsive_btns_group{
     display: none;
 }
 .list{
     padding: 0.25rem 0 0 0;
 }
 .list_item{
     margin: 0.3125rem 0;
 }
 .list_item_en span{
     font-family: 'Times New Roman', Times, serif !important;
 }
 
 .list_icon{
     font-size: 1.25rem;
     color: var(--slate_blue);
     margin-inline: 0.3125rem;
 }
 .delete_icon{
     position: absolute;
     top: -0.9375rem;
     right: 0.9375rem;
     font-size: 1.125rem;
     background-color: white;
     color: var(--main_color);
     padding: 0.625rem 0.6875rem;
     border-radius: 50%;
     box-shadow:  0 0 0.1875rem rgba(0, 0, 0, 0.456);
     cursor: pointer;
     transition: all .4s;
 }
 .delete_icon_ar{
     position: absolute;
     top: -15px;
     left: 15px;
     font-size: 1.125rem;
     background-color: white;
     color: var(--main_color);
     padding: 0.625rem 0.6875rem;
     border-radius: 50%;
     box-shadow:  0 0 0.1875rem rgba(0, 0, 0, 0.456);
     cursor: pointer;
     transition: all .4s;
 }
 .delete_icon:hover,.delete_icon_ar:hover{
     color: white;
     background-color: var(--main_color)
 }
 .noCards{
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     padding: 3.125rem;
 }
 .noCards_img{
     width: 500px;
 }
 .gift_icon{
    color: gold !important;
 }

 .header{
    width: 100%;
    margin-bottom: 1.25rem;
 }
 .header_list{
    display: flex;
    justify-content:center;
    align-items: center;
 }
 .header_list_item{
    background-color: white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.325);
    color: black;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all .4s;
    min-width: 100px;
    text-align: center;
    margin: 5px 20px;
}
 .active{
    background-color: var(--sub_color);
    color: white;
 }
.header_list_item:hover{
    transform: translateY(-3px);
}
 .shop_logo{
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
    margin: auto;
    box-shadow:  0 0 0.1875rem rgba(0, 0, 0, 0.3);
 }
 .shop_logo img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
 }
 .price_value{
    font-size: 1.25rem;
    margin-bottom: 0.625rem;
 }

 .scanner{
    width: 6.25rem;
    height: 6.25rem;
 }

 .steps_div{
    padding: 1.25rem;
    background-color:white;
    min-width:70%;
    border-radius:30px;
    box-shadow: 0 0 0.1875rem rgba(0, 0, 0, 0.359);
 }
 .steps_div ul{
    padding-inline: 1.25rem;
 }
 .steps_div ul li{
    font-size: 1.0625rem;
    padding: 0.625rem 0;
    list-style:square;
 }
 .scan_img{
    width: 150px;
    margin: auto;
 }

 .main_title_div{
    display: flex;
    align-items: center;
}
.logo{
    width: 70px;
}
.main_title_div h2{
    margin: 0;
    margin-inline: 1.25rem;
    font-size: 1.75rem;
}

 @media (max-width: 768px) {
    .header_list_item{
        margin: 5px;

    }
   }

 @media (max-width: 500px) {
    .logo{width: 50px;}

     .btns_group {
       display: none;
     }
     .responsive_btns_group{
         display: flex;
     }
     .noCards_img{
         width: 100%;
     }
     .steps_div{
        min-width:95%
     }
     .steps_div ul{
        padding-inline: 1.25rem;
     }
     .header_list_item{
        margin: 4px;
        padding-inline: 10px;
    }
   }

 @media (max-width: 440px) {
    .header_list_item{
        font-size: 14px;
        padding-inline:8px;
    }
   }
 @media (max-width: 425px) {
    .header_list_item{
        font-size: 12px;
        padding-inline:8px;
    }

   }


 @media (max-width: 390px) {
    .header_list{
        flex-wrap: wrap;
    }
    .logo{display: none;} 
   
    .price_value{
        font-size: 16px;
    }
}
 @media (max-width: 250px) {
    .scan_img{
        width: 100%;
        margin: auto;
     }

   }